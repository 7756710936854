export const environment = {
  production: false,
  authServerHost: 'https://api.leerplus.dev.gametailors.com/auth/',
  casHost: 'https://api.leerplus.dev.gametailors.com/cas',
  dataPackage: 'leerplus-dev',
  defaultGame: 'leerplus-dev',
  aspectRatio: { width: 1920, height: 1080 },
  playGameUrl: 'https://play.leerplus.dev.gametailors.com/',

  version_latestBuildNumber: '1227',
  version_latestCommitHash: 'c8d0d962fd17cff90d50e00c7cdb527850540b0f',
  version_latestDate: '27/01/2025 11:01',
};
