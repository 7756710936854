export const environment = {
  production: false,
  authServerHost: 'https://api.leerplus.dev.gametailors.com/auth/',
  casHost: 'https://api.leerplus.dev.gametailors.com/cas',
  dataPackage: 'leerplus-dev',
  defaultGame: 'leerplus-dev',
  aspectRatio: { width: 1920, height: 1080 },
  playGameUrl: 'https://play.leerplus.dev.gametailors.com/',

  version_latestBuildNumber: '1212',
  version_latestCommitHash: '2bced8399b49fdc9f755d33e8e9944895b253d48',
  version_latestDate: '16/01/2025 10:47',
};
