<div class="d-flex justify-content-between">
  <div class="d-flex flex-column">
    <button (click)="addVisualElement('Rectangle')" class="btn btn-primary d-flex flex-column add-canvas-element">
      <i class="bi bi-image mx-auto"></i>
      <span class="add-canvas-element-text mx-auto">Visual</span>
    </button>
    <button
      (click)="addInstance('ClickTarget', 'Rectangle')"
      *ngIf="dataInstance?.dataType === 'ClickActivity'"
      class="btn btn-primary d-flex flex-column add-canvas-element"
    >
      <i class="bi bi-hand-index mx-auto"></i>
      <span class="add-canvas-element-text mx-auto">Clickable</span>
    </button>
    <button
      (click)="addInstance('Draggable', 'Rectangle')"
      *ngIf="dataInstance?.dataType === 'DragAndDropActivity'"
      class="btn btn-primary d-flex flex-column add-canvas-element"
    >
      <i class="bi bi-arrows-move mx-auto"></i>
      <span class="add-canvas-element-text mx-auto">Draggable</span>
    </button>
    <button
      (click)="addInstance('DropPoint', 'Rectangle')"
      *ngIf="dataInstance?.dataType === 'DragAndDropActivity'"
      class="btn btn-primary d-flex flex-column add-canvas-element"
    >
      <i class="bi bi-square mx-auto"></i>
      <span class="add-canvas-element-text mx-auto">Drop point</span>
    </button>
    <!--    <button class="btn btn-primary d-flex flex-column add-canvas-element">-->
    <!--      <i class="bi bi-display mx-auto"></i>-->
    <!--      <span class="add-canvas-element-text mx-auto">Display</span>-->
    <!--    </button>-->
    <!--    <button class="btn btn-primary d-flex flex-column add-canvas-element">-->
    <!--      <i [ngStyle]="{ transform: 'rotate(90deg)' }" class="bi bi-code-square mx-auto"></i>-->
    <!--      <span class="add-canvas-element-text mx-auto">Rotator</span>-->
    <!--    </button>-->
    <!--    <button class="btn btn-primary d-flex flex-column add-canvas-element">-->
    <!--      <i class="bi bi-toggle-on mx-auto"></i>-->
    <!--      <span class="add-canvas-element-text mx-auto">Toggle</span>-->
    <!--    </button>-->
    <!--    <button class="btn btn-primary d-flex flex-column add-canvas-element">-->
    <!--      <i class="bi bi-sliders mx-auto"></i>-->
    <!--      <span class="add-canvas-element-text mx-auto">Slider</span>-->
    <!--    </button>-->
    <button
      (click)="addMapPinLocation()"
      *ngIf="dataInstance?.dataType === 'Map'"
      class="btn btn-primary d-flex flex-column add-canvas-element"
    >
      <i class="bi bi-pin mx-auto"></i>
      <span class="add-canvas-element-text mx-auto">Map pin</span>
    </button>
  </div>

  <div [ngStyle]="{ 'max-width': '800px', 'max-height': '450px' }">
    <app-visual-editor
      (backgroundImageSize)="onBackgroundImageSizeChange($event)"
      (nameChanged)="onUpdateName($event)"
      (shapeSelected)="onShapeSelected($event)"
      *ngIf="backgroundInstance"
      [backgroundColor]="backgroundColor"
      [backgroundMedia]="backgroundMedia"
      [canvasRatio]="canvasRatio"
      [contextMenuOptions]="contextMenu"
      [currentBackgroundChoice]="currentBackgroundChoice"
      [selectedShape]="selected"
      [targets]="visualTargets"
    />
  </div>

  <div *ngIf="dataInstance" [ngStyle]="{ padding: 0, 'max-height': '450px', width: '-webkit-fill-available' }" class="overflow-auto">
    <div *ngIf="!selectedInstance" id="canvasElements">
      <div *ngFor="let instance of canvasElements" class="bg-primary text-white canvasElement">
        <div class="d-flex justify-content-between p-0" role="button">
          <div (click)="onInstanceSelected(instance)" class="d-flex flex-column cursor-pointer align-self-center ms-3" role="none">
            <i *ngIf="instance.dataType === 'VisualElement'" class="bi bi-image mx-auto"></i>
            <i *ngIf="instance.dataType === 'ClickTarget'" class="bi bi-hand-index mx-auto"></i>
            <i *ngIf="instance.dataType === 'Draggable'" class="bi bi-arrows-move mx-auto"></i>
            <i *ngIf="instance.dataType === 'DropPoint'" class="bi bi-square mx-auto"></i>
            <i *ngIf="instance.dataType === 'MapPinLocation'" class="bi bi-pin mx-auto"></i>
          </div>

          <div
            (click)="onInstanceSelected(instance)"
            [ngStyle]="{ width: '-webkit-fill-available' }"
            class="align-content-center ms-3"
            role="none"
          >
            {{ visualTargetNames[instance.randomIdentifier] }}
          </div>

          <div class="bg-primary text-white d-flex">
            <button (click)="onToggleHideInstance(instance)" class="btn btn-secondary btn-space">
              <span *ngIf="visualTargets[instance.randomIdentifier].visible" class="bi bi-eye"></span>
              <span *ngIf="!visualTargets[instance.randomIdentifier].visible" class="bi bi-eye-slash"></span>
            </button>
            <button (click)="onToggleLockInstance(instance)" class="btn btn-secondary btn-space">
              <span *ngIf="visualTargets[instance.randomIdentifier].locked" class="bi bi-lock"></span>
              <span *ngIf="!visualTargets[instance.randomIdentifier].locked" class="bi bi-unlock"></span>
            </button>
            <button (click)="deleteInstance(instance)" class="btn btn-danger btn-space">
              <span class="bi bi-trash"></span>
            </button>
          </div>
        </div>
      </div>
      <div
        (click)="onInstanceSelected(backgroundInstance)"
        class="bg-primary text-white canvasElement align-content-center cursor-pointer"
        role="none"
      >
        <div class="align-content-center ms-3">Canvas settings</div>
      </div>
    </div>
    <div *ngIf="selectedInstance && selectedInstance !== backgroundInstance">
      <div class="canvasElement px-1 bg-primary text-white">
        <div class="d-flex">
          <button (click)="onResetSelected()" class="btn btn-space">
            <span class="bi bi-arrow-left text-white"></span>
          </button>

          <div class="align-content-center">
            {{ visualTargetNames[selectedInstance.randomIdentifier] }}
          </div>

          <div class="bg-primary text-white d-flex ms-auto">
            <button (click)="onToggleHideInstance(selectedInstance)" class="btn btn-secondary btn-space">
              <span *ngIf="visualTargets[selectedInstance.randomIdentifier].visible" class="bi bi-eye"></span>
              <span *ngIf="!visualTargets[selectedInstance.randomIdentifier].visible" class="bi bi-eye-slash"></span>
            </button>
            <button (click)="onToggleLockInstance(selectedInstance)" class="btn btn-secondary btn-space">
              <span *ngIf="visualTargets[selectedInstance.randomIdentifier].locked" class="bi bi-lock"></span>
              <span *ngIf="!visualTargets[selectedInstance.randomIdentifier].locked" class="bi bi-unlock"></span>
            </button>
            <button (click)="deleteInstance(selectedInstance)" class="btn btn-danger btn-space">
              <span class="bi bi-trash"></span>
            </button>
          </div>
        </div>
      </div>
      <div class="accordion-body">
        <app-struct-instance-editor
          [data]="selectedInstance"
          [showHeader]="false"
          titleOverride="{{ visualTargetNames[selectedInstance.randomIdentifier] }}"
        />
      </div>
    </div>
    <div *ngIf="selectedInstance && selectedInstance === backgroundInstance">
      <div class="canvasElement px-1 bg-primary text-white">
        <div class="d-flex">
          <button (click)="onResetSelected()" class="btn btn-space">
            <span class="bi bi-arrow-left text-white"></span>
          </button>

          <div class="align-content-center">Canvas settings</div>

          <div></div>
        </div>
      </div>
      <div class="accordion-body">
        <div
          *ngIf="
            backgroundFieldValue && (currentBackgroundChoice === 'ImagePlaceableMedia' || currentBackgroundChoice === 'VideoPlaceableMedia')
          "
        >
          <app-file-upload
            (newFileSelected)="updateBackground($event.url, $event.mimeType, $event.name)"
            [data]="backgroundFieldValue"
            [fileType]="currentBackgroundChoice === 'ImagePlaceableMedia' ? 'image' : 'video'"
          />
        </div>

        <input
          (colorPickerSelect)="onBackgroundColorChange($event)"
          *ngIf="currentBackgroundChoice === 'SolidColorPlaceableMedia' && backgroundColor"
          [colorPicker]="backgroundColor"
          [cpAlphaChannel]="'always'"
          [cpEyeDropper]="true"
          [cpOKButtonText]="'Save'"
          [cpOKButton]="true"
          [cpOutputFormat]="'hex'"
          [style.background]="backgroundColor"
          [value]="backgroundColor"
          class="form-control"
          style="width: 100px"
        />

        <select
          (ngModelChange)="updateBackgroundInstance()"
          [(ngModel)]="currentBackgroundChoice"
          class="form-select card-header"
          name="dropdown"
        >
          <option *ngFor="let choice of backgroundChoices" [value]="choice.optionId">{{ choice.label | prettifyName }}</option>
        </select>

        <app-struct-instance-editor
          *ngIf="backgroundInstance.dataType === 'ImagePlaceableMedia' || backgroundInstance.dataType === 'VideoPlaceableMedia'"
          [data]="backgroundInstance"
          [hideIfEmpty]="true"
          [showHeader]="false"
          [showMedia]="false"
        />

        <div class="d-flex flex-wrap mt-1 gap-1 align-items-baseline ms-2">
          <label class="d-flex gap-2 align-items-center">
            <span>Canvas ratio width multiplier:</span>
            <input [(ngModel)]="newCanvasRatioWidth" class="form-control form-control-sm" min="1" style="width: 5rem" type="number" />
          </label>

          <label class="d-flex gap-2 align-items-center">
            Height multiplier:
            <input [(ngModel)]="newCanvasRatioHeight" class="form-control form-control-sm" min="1" style="width: 5rem" type="number" />
          </label>

          <button (click)="adjustCanvasRatio()" class="btn btn-outline-primary ms-1" type="button">Adjust canvas</button>

          <button (click)="adjustCanvasToBackground()" *ngIf="backgroundImageSize" class="btn btn-outline-primary ms-1" type="button">
            Fit canvas to background
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
