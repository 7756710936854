import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import Konva from 'konva';
import { distinctUntilChanged, filter, lastValueFrom, map, mergeMap, startWith, Subscription, switchMap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AlertService } from '@services/UI-elements/alert-service';
import { clamp, debounce, Logger, Try, Vector2 } from '@services/utils';
import {
  ContextMenuOptions,
  DataInstanceFieldData,
  LineGuide,
  LineStops,
  Orientation,
  ShapeType,
  SnappingEdge,
  TargetType,
  VisualTarget,
} from '../canvas-editor/canvas-editor.types';
import { DataInstance } from '@services/entities';
import { DataInstanceRepository } from '@services/repositories';
import { FileEndpoints } from '@services/api';
import Shape = Konva.Shape;

@Component({
  selector: 'app-visual-editor',
  templateUrl: './visual-editor.component.html',
  styleUrls: ['./visual-editor.component.scss'],
})
export class VisualEditorComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('stageContainer') stageContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('stageParent') stageParent!: ElementRef<HTMLDivElement>;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('backgroundVideo') backgroundVideo?: ElementRef<HTMLVideoElement>;

  @Input({ required: true }) targets!: Record<string, VisualTarget>;
  @Input({ required: true }) currentBackgroundChoice?: string;
  @Input({ required: true }) canvasRatio!: Vector2;
  @Input() backgroundMedia?: { url: string; mimeType: string; name: string };
  @Input() backgroundColor?: string;
  @Input() contextMenuOptions?: ContextMenuOptions;
  @Input() selectedShape?: string;

  @Output() shapeSelected = new EventEmitter<string>();
  @Output() nameChanged = new EventEmitter<{ instance: DataInstance; name: string }>();
  @Output() backgroundImageSize = new EventEmitter<Vector2 | undefined>();

  canvasOrientation: Orientation =
    environment.aspectRatio.width > environment.aspectRatio.height ? Orientation.Horizontal : Orientation.Vertical;

  guidelineOffset = 5;
  currentBackgroundVideoUrl?: string;
  currentBackgroundName = '';

  protected editMenuSubject?: Konva.Shape;
  protected showContextMenu?: { x: number; y: number };
  protected readonly Orientation = Orientation;
  private stage?: Konva.Stage;
  private stageSize: Vector2 = Vector2.zero;
  private stagePosition: Vector2 = Vector2.zero;
  private canvasSize: Vector2 = Vector2.zero;
  private shapeLayer = new Konva.Layer();
  private backgroundLayer = new Konva.Layer();
  private transformer = new Konva.Transformer({
    rotateEnabled: false,
    ignoreStroke: true,
    keepRatio: true,
    anchorSize: 15,
    anchorStyleFunc: (anchor) => {
      if (anchor.hasName('top-left') || anchor.hasName('top-right') || anchor.hasName('bottom-right') || anchor.hasName('bottom-left')) {
        anchor.cornerRadius(10);
      }
    },
  });
  private tooltipText = new Konva.Text({
    text: '',
    fontFamily: 'Calibri',
    fontSize: 24,
    padding: 5,
    textFill: 'white',
    fill: 'black',
    alpha: 0.75,
    visible: false,
    listening: false,
  });
  private currentBackgroundUrl?: string;
  private currentBackgroundMimeType?: string;
  private subscriptions: Subscription[] = [];
  private mousePosition = { x: 0, y: 0 };
  private routeSub?: Subscription;
  private instanceUpdatedSub?: Subscription;

  constructor(
    private alertService: AlertService,
    private dataInstanceRepository: DataInstanceRepository,
    private fileEndpoints: FileEndpoints,
  ) {}

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Control') {
      this.transformer.keepRatio(false);
    }
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Control') {
      this.transformer.keepRatio(true);
    }
  }

  async ngOnInit() {
    this.instanceUpdatedSub = this.dataInstanceRepository.entityUpdated$.subscribe(async (instance) => {
      const instanceIdentifier = await instance?.identifier;

      if (
        !instance ||
        !instanceIdentifier ||
        !this.targets[instanceIdentifier] ||
        (instance.dataType !== 'ClickTarget' &&
          instance.dataType !== 'Draggable' &&
          instance.dataType !== 'DropPoint' &&
          instance.dataType !== 'DropArea' &&
          instance.dataType !== 'VisualElement')
      ) {
        return;
      }

      const target = this.targets[instanceIdentifier];

      target.hideBorder = Try(() => instance.fieldValues['hideInteractableIndicator']?.value == 'true') ?? false;
      target.isCorrect = Try(() => instance.fieldValues['isCorrect']?.value == 'true') ?? false;

      const newName = instance.getName();
      if (target.name !== newName) {
        target.name = newName;
        this.nameChanged.emit({ instance: instance, name: newName });
      }

      const konvaShape = target.konvaShape as Konva.Shape[];
      const [shape] = konvaShape;
      if (instance.dataType !== 'Draggable' && instance.dataType !== 'VisualElement') {
        shape.stroke(target.hideBorder ? 'black' : target.isCorrect ? 'lime' : 'red');
      }
    });
  }

  async ngAfterViewInit() {
    const stageSize = this.resizeStage();

    this.stage = new Konva.Stage({
      container: this.stageContainer.nativeElement,
      width: stageSize.width,
      height: stageSize.height,
    });

    this.stage.add(this.backgroundLayer);

    this.shapeLayer.add(this.transformer);
    this.stage.add(this.shapeLayer);

    const repositionStage = () => {
      if (!this.stage) return;

      const dx = this.scrollContainer.nativeElement.scrollLeft;
      const dy = this.scrollContainer.nativeElement.scrollTop;

      this.stage.container().style.transform = 'translate(' + dx + 'px, ' + dy + 'px)';
      this.stage.x(-dx);
      this.stage.y(-dy);

      this.stagePosition = new Vector2({ x: dx, y: dy });
    };

    this.scrollContainer.nativeElement.addEventListener('scroll', repositionStage);

    const tooltipLayer = new Konva.Layer({
      width: stageSize.width,
      height: stageSize.height,
    });

    tooltipLayer.add(this.tooltipText);
    this.stage.add(tooltipLayer);

    this.stage.on('click tap', (e) => {
      if (e.target === this.stage) {
        this.transformer.nodes([]);
        this.shapeSelected.emit('');
      }
    });

    this.stage.on('mousedown', (e) => {
      this.showContextMenu = undefined;
      if (e.target !== this.editMenuSubject && !e.target.name().includes('_anchor')) {
        this.editMenuSubject = undefined;
      }
    });

    this.stage.on('contextmenu', this.onContextMenu.bind(this));

    if (this.currentBackgroundChoice === 'SolidColorPlaceableMedia' && this.backgroundColor && this.stage) {
      await this.updateBackgroundColor(this.backgroundColor);
    }

    this.initTargets();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if ('targets' in changes) {
      const previousValue = changes['targets'].previousValue as Record<string, VisualTarget> | undefined;

      if (previousValue) {
        for (const sub of this.subscriptions) {
          sub.unsubscribe();
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [_id, target] of Object.entries(previousValue)) {
          target.konvaImage?.destroy();
          target.konvaImage = undefined;
          target.konvaShape?.forEach((shape) => shape.destroy());
          target.konvaShape = undefined;
        }
      }

      if (this.stage) this.initTargets();
      this.updateSelectedShape();
    }

    if ('canvasRatio' in changes && this.stage) {
      const stageSize = this.resizeStage();
      this.stage.size(stageSize);
    }

    if ('backgroundMedia' in changes && this.backgroundMedia && this.stage) {
      await this.updateBackground(this.backgroundMedia!.url, this.backgroundMedia!.mimeType, this.backgroundMedia!.name);
    }

    if ('backgroundColor' in changes && this.backgroundColor && this.stage) {
      await this.updateBackgroundColor(this.backgroundColor!);
    }

    if ('selectedShape' in changes) {
      this.updateSelectedShape();
    }
  }

  clampShape(shape: Konva.Shape) {
    if (!this.stage) throw new Error('Stage not initialized');

    if (shape instanceof Konva.Circle) {
      shape.x(clamp(shape.x(), shape.radius(), this.stageSize.x - shape.radius()));
      shape.y(clamp(shape.y(), shape.radius(), this.stageSize.y - shape.radius()));
      return;
    }

    shape.x(clamp(shape.x(), shape.offsetX(), this.stageSize.x - shape.width() + shape.offsetX()));
    shape.y(clamp(shape.y(), shape.offsetY(), this.stageSize.y - shape.height() + shape.offsetY()));
  }

  async saveShape(target: VisualTarget) {
    switch (target.type) {
      case ShapeType.RECTANGLE: {
        if (!target.konvaShape) throw new Error('Konva shape not found');

        const shape = target.konvaShape[0];
        const size = shape.size();
        const position = shape.position();

        target.size.fieldValue.value = new Vector2({
          x: (size.width / this.stageSize.x) * this.canvasRatio.x,
          y: (size.height / this.stageSize.y) * this.canvasRatio.y,
        });
        target.position.fieldValue.value = new Vector2({
          x: (position.x / this.stageSize.x) * this.canvasRatio.x,
          y: (position.y / this.stageSize.y) * this.canvasRatio.y,
        });

        try {
          await Promise.all([
            this.dataInstanceRepository
              .get(target.size.dataInstanceUid)
              .then((di) => di.fieldValues['size']!.set(new Vector2(target.size.fieldValue.value))),
            this.dataInstanceRepository
              .get(target.position.dataInstanceUid)
              .then((di) => di.fieldValues['position']!.set(new Vector2(target.position.fieldValue.value))),
          ]);
        } catch (e) {
          Logger.error('Failed to save shape', e);
          this.alertService.error('Failed to save shape');
        }

        break;
      }

      case ShapeType.PIN: {
        if (!target.konvaShape) throw new Error('Konva shape not found');

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_line, panel, pin] = target.konvaShape;
        const position = pin.position();
        const panelPosition = panel.position();

        target.position.fieldValue.value = new Vector2({
          x: (position.x / this.stageSize.x) * this.canvasRatio.x,
          y: (position.y / this.stageSize.y) * this.canvasRatio.y,
        });
        target.panelPosition.fieldValue.value = new Vector2({
          x: (panelPosition.x / this.stageSize.x) * this.canvasRatio.x,
          y: (panelPosition.y / this.stageSize.y) * this.canvasRatio.y,
        });

        try {
          await Promise.all([
            this.dataInstanceRepository
              .get(target.panelPosition.dataInstanceUid)
              .then((di) => di.fieldValues['panelPosition']!.set(new Vector2(target.panelPosition.fieldValue.value))),
            this.dataInstanceRepository
              .get(target.position.dataInstanceUid)
              .then((di) => di.fieldValues['position']!.set(new Vector2(target.position.fieldValue.value))),
          ]);
        } catch (e) {
          Logger.error('Failed to save pin', e);
          this.alertService.error('Failed to save shape');
        }
        break;
      }

      case ShapeType.CIRCLE: {
        if (!target.konvaShape) throw new Error('Konva shape not found');

        const shape = target.konvaShape[0] as Konva.Circle;
        const radius = shape.radius();
        const position = shape.position();

        target.radius.fieldValue.value = (radius / this.stageSize.x) * this.canvasRatio.x;
        target.position.fieldValue.value = new Vector2({
          x: (position.x / this.stageSize.x) * this.canvasRatio.x,
          y: (position.y / this.stageSize.y) * this.canvasRatio.y,
        });

        try {
          await Promise.all([
            this.dataInstanceRepository
              .get(target.radius.dataInstanceUid)
              .then((di) => di.fieldValues['radius']!.set(target.radius.fieldValue.value)),
            this.dataInstanceRepository
              .get(target.position.dataInstanceUid)
              .then((di) => di.fieldValues['position']!.set(new Vector2(target.position.fieldValue.value))),
          ]);
        } catch (e) {
          Logger.error('Failed to save circle', e);
          this.alertService.error('Failed to save shape');
        }
        break;
      }
      default:
        console.warn(`Unknown shape type: ${target}`);
        return;
    }
  }

  async updateBackground(url: string, mimeType = 'image/png', name: string) {
    if (!this.stage) throw new Error('Stage not initialized');

    this.currentBackgroundUrl = url;
    this.currentBackgroundMimeType = mimeType;
    this.currentBackgroundName = name;

    this.currentBackgroundVideoUrl = undefined;
    this.stage.findOne('#background')?.destroy();

    // Create a rectangle over the full stage
    const rect = new Konva.Rect({
      width: this.stageSize.x,
      height: this.stageSize.y,
      id: 'background',
      listening: false,
      opacity: 0,
    });

    this.backgroundLayer.add(rect);

    const backgroundImages = this.stage.find('#backgroundImage');
    if (backgroundImages) {
      for (const backgroundImage of backgroundImages) {
        backgroundImage.destroy();
      }
    }

    if (mimeType.includes('video')) {
      this.currentBackgroundVideoUrl = url;
      return;
    }

    // Add the image to the rectangle
    let konvaImg: Konva.Image;
    Konva.Image.fromURL(url, (image) => {
      konvaImg = image;
      image.listening(false);
      konvaImg.id('backgroundImage');
      if (image) this.backgroundImageSize.emit(new Vector2([image.width(), image.height()]));
      else this.backgroundImageSize.emit(undefined);
      this.updateImage(image, rect);
      this.backgroundLayer.add(image);
    });
  }

  async updateBackgroundColor(color: string) {
    if (!this.stage) throw new Error('Stage not initialized');

    this.backgroundColor = color;

    this.stage.findOne('#background')?.destroy();
    // Create a rectangle over the full stage
    const rect = new Konva.Rect({
      width: this.stageSize.x,
      height: this.stageSize.y,
      id: 'background',
      listening: false,
      opacity: 100,
      fill: this.backgroundColor,
    });
    this.backgroundLayer.add(rect);

    const backgroundImages = this.stage.find('#backgroundImage');
    if (backgroundImages) {
      for (const backgroundImage of backgroundImages) {
        backgroundImage.destroy();
      }
    }
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.routeSub?.unsubscribe();
    this.instanceUpdatedSub?.unsubscribe();

    if (this.stage) this.stage.destroy();

    this.shapeLayer.destroy();
    this.backgroundLayer.destroy();
    this.transformer.destroy();
  }

  protected runContextMenuAction(action: (x: number, y: number) => void) {
    action(this.mousePosition.x, this.mousePosition.y);
    this.showContextMenu = undefined;
  }

  private updateSelectedShape() {
    if (this.selectedShape === '') {
      this.transformer.nodes([]);
    } else {
      const target = Object.values(this.targets).find((t) => t.name === this.selectedShape && t.visible && !t.locked);
      if (target) {
        const shape = target.konvaShape?.[0];
        if (shape) {
          this.transformer.nodes([shape]);
        }
      } else {
        this.transformer.nodes([]);
      }
    }
  }

  private createRectangle(
    name: string,
    uid: string,
    locked: boolean,
    size: Vector2,
    position: Vector2,
    image?: DataInstanceFieldData<string>,
  ): Konva.Rect {
    const rect = new Konva.Rect({
      // Convert the width and height to pixels
      width: size.x * this.canvasSize.x,
      height: size.y * this.canvasSize.y,
      x: position.x * this.canvasSize.x,
      y: position.y * this.canvasSize.y,
      fill: 'transparent',
      stroke: 'red',
      strokeWidth: 4,
      draggable: !locked,
      strokeScaleEnabled: false,
      dash: locked ? [] : [10, 5],
      shadowBlur: 10,
      cornerRadius: 2,
      name: name,
      listening: true,
      transformable: !locked,
    });

    if (image) {
      this.initImage(rect, this.targets[uid], { image });
    }

    return rect;
  }

  private createCircle(
    name: string,
    uid: string,
    locked: boolean,
    radius: number,
    position: Vector2,
    image: DataInstanceFieldData<string> | undefined,
  ): Konva.Circle {
    const circle = new Konva.Circle({
      radius: radius * this.canvasSize.x,
      fill: 'transparent',
      stroke: 'red',
      strokeWidth: 4,
      draggable: !locked,
      strokeScaleEnabled: false,
      dash: locked ? [] : [10, 5],
      shadowBlur: 10,
      name: name,
      x: position.x * this.canvasSize.x,
      y: position.y * this.canvasSize.y,
      transformable: !locked,
    });

    if (image) {
      this.initImage(circle, this.targets[uid], { image });
    }

    return circle;
  }

  private createPin(name: string, locked: boolean, position: Vector2, panelPosition: Vector2) {
    const panel = new Konva.Rect({
      width: 100,
      height: 100,
      fill: 'white',
      stroke: 'black',
      strokeWidth: 2,
      draggable: !locked,
      name: name,
      x: panelPosition.x * this.stageSize.x,
      y: panelPosition.y * this.stageSize.y,
      offset: {
        x: 50,
        y: 100,
      },
    });

    const pin = new Konva.Circle({
      radius: 10,
      fill: 'red',
      stroke: 'black',
      strokeWidth: 2,
      draggable: !locked,
      name: name,
      x: position.x * this.stageSize.x,
      y: position.y * this.stageSize.y,
    });

    const line = new Konva.Line({
      points: [0, 0, 0, 0],
      stroke: 'black',
      strokeWidth: 2,
      lineCap: 'round',
      lineJoin: 'round',
      dash: locked ? [] : [10, 5],
      name: name,
    });

    const updateLine = () => {
      line.points([pin.x(), pin.y(), panel.x(), panel.y()]);
    };

    updateLine();

    pin.on('dragmove', updateLine);
    panel.on('dragmove', updateLine);

    return [line, panel, pin];
  }

  private createShape(target: VisualTarget) {
    const createdShapes: Konva.Shape[] = [];

    switch (target.type) {
      case ShapeType.RECTANGLE: {
        const { size, position, media } = target;
        createdShapes.push(
          this.createRectangle(target.name, target.uid, target.locked, size.fieldValue.value, position.fieldValue.value, media),
        );
        break;
      }

      case ShapeType.CIRCLE: {
        const { radius, position, media } = target;
        createdShapes.push(
          this.createCircle(target.name, target.uid, target.locked, radius.fieldValue.value, position.fieldValue.value, media),
        );
        break;
      }

      case ShapeType.PIN: {
        const { position, panelPosition } = target;
        createdShapes.push(...this.createPin(target.name, target.locked, position.fieldValue.value, panelPosition.fieldValue.value));
        break;
      }

      default:
        console.warn(`Unknown shape: ${target}`);
        return;
    }

    for (const shape of createdShapes) {
      // position and sizes
      shape.on('dragmove', () => {
        this.clampShape(shape);
        this.shapeLayer.find('.guide-line').forEach((l) => l.destroy());

        const lineGuideStops = this.getLineGuideStops(shape, target.konvaImage);
        const itemBounds = this.getObjectSnappingEdges(shape);
        const guides = this.getGuides(lineGuideStops, itemBounds);

        if (guides.length <= 0) {
          return;
        }

        this.drawGuides(guides);

        const absPos = shape.absolutePosition();
        guides.forEach((lg) => {
          switch (lg.orientation) {
            case 'V': {
              absPos.x = lg.lineGuide + lg.offset;
              break;
            }
            case 'H': {
              absPos.y = lg.lineGuide + lg.offset;
              break;
            }
          }
        });
        shape.absolutePosition(absPos);
      });
      shape.on('dragend transformend updatedFromMenuSubject', async () => {
        await this.saveShape(target);
        this.shapeLayer.find('.guide-line').forEach((l) => l.destroy());
      });
      shape.on('transform', () => {
        shape.width(Math.max(5, shape.width() * shape.scaleX()));
        shape.height(Math.max(5, shape.height() * shape.scaleY()));
        shape.scaleX(1);
        shape.scaleY(1);
      });

      // tooltip
      shape.on('mouseenter', () => {
        this.tooltipText.text(target.name || shape.name());
        this.tooltipText.fill(shape.stroke());
        this.tooltipText.show();
      });

      shape.on('mouseleave', () => {
        this.tooltipText.hide();
      });

      const onMouseMove = debounce(() => {
        const position = this.stage?.getPointerPosition();
        if (position) {
          this.tooltipText.position({
            x: position.x + this.stagePosition.x,
            y: position.y + this.stagePosition.y,
          });
        }
      });
      shape.on('mousemove', onMouseMove);

      // enable transformer
      shape.on('click tap', () => {
        if (shape.getAttr('transformable')) {
          this.transformer.nodes([shape]);
        } else {
          this.transformer.nodes([]);
        }
        this.shapeSelected.emit(target.name);
      });
    }

    this.shapeLayer.add(...createdShapes);
    this.transformer.moveToTop();

    return createdShapes;
  }

  private resizeStage() {
    const { width, height } = environment.aspectRatio;

    const ratio = height / width;
    this.canvasSize = ratio > 1 ? new Vector2({ x: 450, y: 800 }) : new Vector2({ x: 800, y: 450 });
    this.canvasOrientation = ratio > 1 ? Orientation.Vertical : Orientation.Horizontal;

    const canvasWidth = this.canvasSize.x * this.canvasRatio.x;
    const canvasHeight = this.canvasSize.y * this.canvasRatio.y;

    this.stageSize = new Vector2({ x: canvasWidth, y: canvasHeight });

    this.stageParent.nativeElement.style.width = canvasWidth + 'px';
    this.stageParent.nativeElement.style.height = canvasHeight + 'px';

    if (this.currentBackgroundUrl) {
      this.updateBackground(this.currentBackgroundUrl, this.currentBackgroundMimeType, this.currentBackgroundName).then();
    }

    return {
      width: this.canvasSize.x,
      height: this.canvasSize.y,
    };
  }

  private onContextMenu(konvaEventObject: Konva.KonvaEventObject<MouseEvent>) {
    konvaEventObject.evt.preventDefault();

    this.showContextMenu = undefined;

    if (konvaEventObject.target !== this.stage) {
      this.editMenuSubject = konvaEventObject.target as Konva.Shape;
      return;
    }

    const pos = this.stage.getRelativePointerPosition();
    if (!pos) {
      return;
    }

    this.mousePosition = {
      x: (pos.x / this.stageSize.x) * this.canvasRatio.x,
      y: (pos.y / this.stageSize.y) * this.canvasRatio.y,
    };

    const visualEditor = document.getElementById('visual-editor');
    if (!visualEditor) return;

    // set the context menu position relative to the editor, ignoring scroll
    this.showContextMenu = pos;
  }

  private initTargets() {
    if (!this.stage) {
      console.warn("Can't init targets, stage is undefined");
      return;
    }

    for (const target of Object.values(this.targets)) {
      if (!target.konvaShape && target.visible) {
        target.konvaShape = this.createShape(target);

        const konvaShape = target.konvaShape as Konva.Shape[];
        const [shape] = konvaShape;

        shape.stroke(target.hideBorder ? 'black' : target.isCorrect ? 'lime' : 'red');

        if (!target.konvaShape) continue;

        switch (target.targetType) {
          case TargetType.VISUAL_ELEMENT: {
            shape.stroke('blue');
            break;
          }

          case TargetType.DRAGGABLE: {
            shape.stroke('yellow');
            break;
          }
        }
      }
    }

    this.editMenuSubject = undefined;
  }

  private initImage(
    linkedShape: Konva.Shape,
    linkedTarget: VisualTarget,
    {
      image,
    }: {
      image: DataInstanceFieldData<string>;
    },
  ) {
    let konvaImg: Konva.Image;
    linkedShape.on('transformend dragmove', () => this.updateImage(konvaImg, linkedShape));

    const sub = this.dataInstanceRepository.entityUpdated$
      .pipe(
        mergeMap(async (instance) => ({
          instance,
          isValid: !!instance && (await instance.identifier) === image.dataInstanceUid,
        })),
        filter(({ isValid }) => isValid),
        map(({ instance }) => {
          if (instance) {
            const imageField = Try(() => instance.fieldValues['image']);
            if (imageField) return imageField.value as string;

            const videoField = Try(() => instance.fieldValues['video']);
            if (videoField) return videoField.value as string;
          }

          return undefined;
        }),
        startWith(image.fieldValue.value),
        distinctUntilChanged(),
        switchMap((imageUid) =>
          this.fileEndpoints.fileUploaded$.pipe(
            filter((file) => file.uid === imageUid),
            map(() => imageUid),
            startWith(imageUid),
          ),
        ),
      )
      .subscribe((imageUid) => {
        if (konvaImg) {
          konvaImg.destroy();
          linkedTarget.konvaImage = undefined;
          if (linkedTarget.type === ShapeType.RECTANGLE || linkedTarget.type === ShapeType.CIRCLE) {
            linkedTarget.media.fieldValue.value = '';
          }
        }

        if (imageUid) {
          lastValueFrom(this.fileEndpoints.downloadFile(imageUid)).then((blob) => {
            const url = URL.createObjectURL(blob);

            // TODO: I think the extremely long image load times is because of this function.
            Konva.Image.fromURL(url, (image) => {
              konvaImg = image;
              image.listening(false);

              this.shapeLayer.add(image);
              linkedTarget.konvaImage = image;
              if (linkedTarget.type === ShapeType.RECTANGLE || linkedTarget.type === ShapeType.CIRCLE) {
                linkedTarget.media.fieldValue.value = imageUid;
              }
              this.updateImage(image, linkedShape);

              URL.revokeObjectURL(url);
            });
          });
        }
      });

    this.subscriptions.push(sub);
  }

  private updateImage(image: Konva.Image, shape: Konva.Shape) {
    if (!image) return;

    const isCircle = shape instanceof Konva.Circle;

    // center image in the shape, keep image aspect ratio
    const w = shape.width() * shape.scaleX();
    const h = shape.height() * shape.scaleY();
    const scale = Math.min(w / image.width(), h / image.height());

    image.scale({ x: scale, y: scale });
    image.position({
      x: isCircle ? shape.x() : shape.x() + w / 2,
      y: isCircle ? shape.y() : shape.y() + h / 2,
    });

    image.offset({
      x: image.width() / 2,
      y: image.height() / 2,
    });
  }

  private getLineGuideStops(skipShape: Shape, skipImage?: Konva.Image): { vertical: number[]; horizontal: number[] } {
    if (!this.stage) throw new Error('Stage not initialized');

    const verticalStops = [this.stage.width() / 2];
    const horizontalStops = [0, this.stage.height() / 2, this.stage.height()];

    this.shapeLayer.find('Shape').forEach((shape) => {
      if (shape === skipShape || shape === skipImage || shape.name().includes('_anchor') || shape.hasName('back')) {
        return;
      }
      const box = shape.getClientRect();
      // The clientRect is too big, so we adjust the values slightly
      verticalStops.push(box.x + 10, box.x - 10 + box.width, box.x + box.width / 2);
      horizontalStops.push(box.y + 10, box.y - 10 + box.height, box.y + box.height / 2);
    });
    return {
      vertical: verticalStops,
      horizontal: horizontalStops,
    };
  }

  private getObjectSnappingEdges(shape: Shape): { vertical: SnappingEdge[]; horizontal: SnappingEdge[] } {
    // The clientRect is too big, so we need to adjust the values slightly
    const box = shape.getClientRect();
    const absPos = shape.absolutePosition();

    return {
      vertical: [
        {
          guide: Math.round(box.x + 10),
          offset: Math.round(absPos.x - box.x - 10),
          snap: 'start',
        },
        {
          guide: Math.round(box.x + box.width / 2),
          offset: Math.round(absPos.x - box.x - box.width / 2),
          snap: 'center',
        },
        {
          guide: Math.round(box.x - 10 + box.width),
          offset: Math.round(absPos.x - box.x + 10 - box.width),
          snap: 'end',
        },
      ],
      horizontal: [
        {
          guide: Math.round(box.y + 10),
          offset: Math.round(absPos.y - box.y - 10),
          snap: 'start',
        },
        {
          guide: Math.round(box.y + box.height / 2),
          offset: Math.round(absPos.y - box.y - box.height / 2),
          snap: 'center',
        },
        {
          guide: Math.round(box.y - 10 + box.height),
          offset: Math.round(absPos.y - box.y + 10 - box.height),
          snap: 'end',
        },
      ],
    };
  }

  private getGuides(
    lineGuideStops: { vertical: number[]; horizontal: number[] },
    itemBounds: {
      vertical: SnappingEdge[];
      horizontal: SnappingEdge[];
    },
  ) {
    const resultVertical: LineStops[] = [];
    const resultHorizontal: LineStops[] = [];

    lineGuideStops.vertical.forEach((lineGuide) => {
      itemBounds.vertical.forEach((itemBound) => {
        const diff = Math.abs(lineGuide - itemBound.guide);
        if (diff < this.guidelineOffset) {
          resultVertical.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });

    lineGuideStops.horizontal.forEach((lineGuide) => {
      itemBounds.horizontal.forEach((itemBound) => {
        const diff = Math.abs(lineGuide - itemBound.guide);
        if (diff < this.guidelineOffset) {
          resultHorizontal.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });

    const guides: LineGuide[] = [];
    const minVertical = resultVertical.sort((a, b) => a.diff - b.diff)[0];
    const minHorizontal = resultHorizontal.sort((a, b) => a.diff - b.diff)[0];
    if (minVertical) {
      guides.push({
        lineGuide: minVertical.lineGuide,
        offset: minVertical.offset,
        orientation: 'V',
        snap: minVertical.snap,
      });
    }
    if (minHorizontal) {
      guides.push({
        lineGuide: minHorizontal.lineGuide,
        offset: minHorizontal.offset,
        orientation: 'H',
        snap: minHorizontal.snap,
      });
    }
    return guides;
  }

  private drawGuides(guides: LineGuide[]) {
    guides.forEach((lg) => {
      if (lg.orientation === 'H') {
        const line = new Konva.Line({
          points: [-6000, 0, 6000, 0],
          stroke: 'rgb(0, 161, 255)',
          strokeWidth: 1,
          name: 'guide-line',
          dash: [4, 6],
        });
        this.shapeLayer.add(line);
        line.absolutePosition({
          x: 0,
          y: lg.lineGuide,
        });
      } else if (lg.orientation === 'V') {
        const line = new Konva.Line({
          points: [0, -6000, 0, 6000],
          stroke: 'rgb(0, 161, 255)',
          strokeWidth: 1,
          name: 'guide-line',
          dash: [4, 6],
        });
        this.shapeLayer.add(line);
        line.absolutePosition({
          x: lg.lineGuide,
          y: 0,
        });
      }
    });
  }
}
