<div class="card">
  <div class="card-header justify-content-between d-flex position-relative">
    <div class="align-content-center">Set Variable Action</div>

    <button (click)="onDelete()" class="btn btn-danger delete-button">
      <i class="bi bi-trash"></i>
    </button>
  </div>

  <div>
    Variable:

    <div class="d-flex">
      <select (change)="onVariableChange()" [(ngModel)]="variableUid" class="form-select">
        <option *ngFor="let variable of variables" [ngValue]="variable.variableRef">
          {{ variable.name }}
        </option>
      </select>

      <button (click)="onViewStruct(currentVariable)" *ngIf="currentVariable" class="btn btn-primary mx-1">
        <i class="bi-box-arrow-up-right"></i>
      </button>

      <button (click)="onAddNewVariable()" class="btn btn-primary">
        <i class="bi-plus-circle"></i>
      </button>
    </div>
  </div>

  <div *ngIf="currentVariable">
    New Value:

    <input
      (change)="update()"
      *ngIf="currentVariable.valueType === VariableType.String"
      [(ngModel)]="value"
      class="form-control"
      type="text"
    />

    <input
      (change)="update()"
      *ngIf="currentVariable.valueType === VariableType.Number"
      [(ngModel)]="value"
      class="form-control"
      type="number"
    />

    <div *ngIf="currentVariable.valueType === VariableType.Boolean" class="form-check form-switch w-100">
      <input (change)="update()" [(ngModel)]="value" class="form-check-input" role="switch" type="checkbox" />
    </div>

    <div *ngIf="currentVariable.valueType === VariableType.Color" class="form-check form-switch w-100">
      <input
        (colorPickerSelect)="update($event)"
        [colorPicker]="String(value)"
        [cpAlphaChannel]="'always'"
        [cpEyeDropper]="true"
        [cpOKButtonText]="'Save'"
        [cpOKButton]="true"
        [cpOutputFormat]="'hex'"
        [style.background]="value"
        [value]="value"
        style="width: 100px"
      />
    </div>

    <div *ngIf="!currentVariable.valueType" class="text-danger">This variable has no type yet, so no value can be set.</div>
  </div>
</div>
