import { Component, Input, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FieldType, FieldTypes, FieldValue } from '@services/entities/helpers';
import { asyncFilter, Logger } from '@services/utils';
import { DataInstanceRepository, EnumTypeRepository, StructTypeRepository } from '@services/repositories';
import { DataInstance, StructType } from '@services/entities';
import { FieldEditorComponent } from '@services/dynamic-field.service';

@Component({
  selector: 'app-inline-list-editor',
  templateUrl: './inline-list-editor.component.html',
  styleUrls: ['./inline-list-editor.component.scss'],
})
export class InlineListEditorComponent implements OnInit, FieldEditorComponent<string[]> {
  @Input({ required: true }) data!: FieldValue;

  value!: string[];

  instances: DataInstance[] = [];
  structTypes: StructType[] = [];
  typeName: string | undefined;
  collapsedStates: boolean[] = [];

  constructor(
    private dataInstanceRepository: DataInstanceRepository,
    private enumTypeRepository: EnumTypeRepository,
    private structTypeRepository: StructTypeRepository,
  ) {}

  get allCollapsed(): boolean {
    return this.collapsedStates.every((state) => state);
  }

  async ngOnInit() {
    if (!this.data) throw new Error('Data not found');
    this.value = this.data.getDeserializedValue(FieldType.LIST, this.data.value) as string[];

    // Get the instances from the data service
    for (const instanceUid of this.value) {
      const instance = await this.dataInstanceRepository.get(instanceUid);
      this.instances.push(instance);
      this.collapsedStates.push(this.value.length > 1);
    }

    const field = this.data.field;
    if (!FieldTypes.isListType(field.type)) throw new Error(`Field type "${field.type}" is not a list`);

    const referencedTypeId = FieldTypes.getReferencedTypeId(field.type);
    if (!referencedTypeId) throw new Error(`Field type "${field.type}" is not a list of enums or structs`);

    try {
      if (await FieldTypes.isEnumTypeValid(referencedTypeId)) {
        const enumType = await this.enumTypeRepository.get(FieldTypes.getDeepestReference(referencedTypeId)!);
        this.typeName = enumType.name;
        this.structTypes = await Promise.all(
          enumType.options.sort().map(async (enumType) => await this.structTypeRepository.get(enumType)),
        );
        return;
      }
    } catch (e) {
      Logger.error(`Failed to validate enum type: ${e}`);
    }

    try {
      if (await FieldTypes.isStructTypeValid(referencedTypeId)) {
        const structType = await this.structTypeRepository.get(FieldTypes.getDeepestReference(referencedTypeId)!);
        this.typeName = structType.name;
        this.structTypes = [structType];
        return;
      }
    } catch (e) {
      Logger.error(`Failed to validate struct type: ${e}`);
    }

    throw new Error(`Field type "${field.type}" is not a list of valid enums or structs`);
  }

  async addInstance(structTypeId: string) {
    if (!this.data) return;

    const dataInstance = await this.dataInstanceRepository.create(structTypeId);
    this.instances.push(dataInstance);

    // Expand the new instance
    this.collapsedStates.push(false);

    // Update the data instance
    if (!this.value || !Array.isArray(this.value)) this.value = [];

    this.value.push(await dataInstance.identifier);
    await this.update();
  }

  async deleteInstance(instanceToDelete: DataInstance) {
    if (!this.data) return;

    // Update the data instance
    const instanceToDeleteIdentifier = await instanceToDelete.identifier;
    this.value = this.value.filter((instanceUid: string) => instanceUid !== instanceToDeleteIdentifier);
    const index = this.instances.findIndex((listInstance: DataInstance) => listInstance === instanceToDelete);

    this.instances = await asyncFilter(
      this.instances,
      async (listInstance: DataInstance) => (await listInstance.identifier) !== instanceToDeleteIdentifier,
    );
    this.collapsedStates.splice(index, 1);

    await this.update();
    await this.dataInstanceRepository.delete(instanceToDelete, true);
  }

  async onStructTypeSelected(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedStructType = selectElement.value;

    if (selectedStructType) {
      await this.addInstance(selectedStructType);
      selectElement.value = '';
    }
  }

  async onMoveDataInstanceOrder(event: CdkDragDrop<DataInstance[]>) {
    if (!this.data || !this.value) return;

    moveItemInArray(this.value, event.previousIndex, event.currentIndex);
    moveItemInArray(this.instances, event.previousIndex, event.currentIndex);
    moveItemInArray(this.collapsedStates, event.previousIndex, event.currentIndex);
    await this.update();
  }

  async update() {
    try {
      await this.data.set(this.value);
    } catch (e) {
      Logger.error(e);
    }
  }

  toggleCollapseStates() {
    const newState = !this.allCollapsed;
    this.collapsedStates = this.collapsedStates.map(() => newState);
  }
}
