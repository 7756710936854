import Konva from 'konva';
import { FieldValueForCanvasEditor } from '@services/types/FieldValueForCanvasEditor';
import { Vector2 } from '@services/utils';

export type DataInstanceFieldData<T> = {
  dataInstanceUid: string;
  fieldValue: FieldValueForCanvasEditor<T>;
};

export enum ShapeType {
  RECTANGLE = 'Rectangle',
  CIRCLE = 'Circle',
  PIN = 'Pin',
}

type Rectangle = {
  type: ShapeType.RECTANGLE;
  size: DataInstanceFieldData<Vector2>;
  media: DataInstanceFieldData<string>;
};

type Circle = {
  type: ShapeType.CIRCLE;
  radius: DataInstanceFieldData<number>;
  media: DataInstanceFieldData<string>;
};

type Pin = {
  type: ShapeType.PIN;
  position: DataInstanceFieldData<Vector2>;
  panelPosition: DataInstanceFieldData<Vector2>;
};

export type VisualTarget = (Rectangle | Circle | Pin) & {
  position: DataInstanceFieldData<Vector2>;
  konvaShape?: Konva.Shape[];
  konvaImage?: Konva.Image;
  name: string;
  isCorrect?: boolean;
  hideBorder: boolean;
  visible: boolean;
  locked: boolean;
  targetType: TargetType;
  uid: string;
};

export type ContextMenuOptions = Record<
  string,
  {
    label: string;
    action: (x: number, y: number) => void;
  }[]
>;

export enum Orientation {
  Vertical,
  Horizontal,
}

export enum TargetType {
  CLICK_TARGET = 'ClickTarget',
  VISUAL_ELEMENT = 'VisualElement',
  DROP_TARGET = 'DropTarget',
  DRAGGABLE = 'Draggable',
  MAP_PIN = 'MapPin',
}

export type SnappingEdge = {
  guide: number;
  offset: number;
  snap: 'start' | 'center' | 'end';
};

export type LineStops = {
  lineGuide: number;
  offset: number;
  snap: 'start' | 'center' | 'end';
  diff: number;
};

export type LineGuide = {
  lineGuide: number;
  offset: number;
  snap: 'start' | 'center' | 'end';
  orientation: 'V' | 'H';
};
